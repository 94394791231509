import {axiosInstance} from './auth-service';
import { BACKEND_URL } from "./config";
// import cookies from "vue-cookies";

// if (cookies.get("user"))
//   axios.defaults.headers.common["Authorization"] = cookies.get("user").token;

export async function create(opts) {
  const res = await axiosInstance.post(`${BACKEND_URL}/organizations`, { ...opts });
  return res.data;
}

export async function getOrganization(organizationId) {
  const res = await axiosInstance.get(`${BACKEND_URL}/organizations/${organizationId}`);
  return res.data;
}
export async function getOrganizations(query) {
  const res = await axiosInstance.get(`${BACKEND_URL}/organizations${query ? '?queryStr=' + query : ''}`);
  return res.data;
}
export async function deleteOrganization(id) {
  const res = await axiosInstance.delete(`${BACKEND_URL}/organizations/${id}`);
  return res.data;
}

export async function searchOrganizations(query) {
  const res = await axiosInstance.get(`${BACKEND_URL}/search-organizations?searchQuery=${query}`);
  return res.data;
}

