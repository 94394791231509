<template>
    <div>
        <Nav />
        <div class="container">
            <br /><br />
            <!-- to fix margin / padding issue, put in flex wrapper -->
            <div style="display: flex; flex-direction: 1;">
                <div class="card" style="flex: 1;">
                    <div class="card-body" style="margin: 2rem 2.5rem">
                        <h1 v-if="!organizationId">Create an Organization</h1>
                        <h1 v-else>Edit Organization</h1>
                    </div>
                </div>
            </div>
    
            <br />
            <div style="display: flex">
            <div class="card" style="flex: 1">
                <div class="card-body">
                    <Form 
                        :data="data"
                        :currentPage="currentPage"
                        :submittable="submittable"
                        @next="next"
                        @submit="submit"
                    />
                </div>
            </div>
            </div>

            <br /><br />
        </div>
    </div>
</template>
<script setup>
import { useRoute } from 'vue-router';
import router from '@/router';
import Nav from '@/components/Nav';
import { ref, onMounted } from 'vue';
// import FileDrop from './FileDrop';
import Form from '@/components/Form.vue';
import { create, getOrganization } from '@/services/organizations-service';

const currentPage = ref(0);
const submittable = ref(true);

const route = useRoute();
const organizationId = route.params.organizationId;

/**
 * paired defaults to true
 * if paired defaults to true, if false it does not connect for labels
 */

const data = ref([
    {
        title: 'General Info',
        flex: true,
        inputs: [
            {
                name: 'name',
                required: true,
                label: 'Name',
                type: 'input',
                placeholder: 'Title',
                value: '',
                paired: false
            },
            {
                name: 'nickname',
                required: false,
                label: 'Nickname',
                type: 'input',
                placeholder: 'Nickname',
                value: '',
                paired: false
            },

            {
                name: 'mascot',
                required: false,
                label: 'Mascot',
                type: 'input',
                placeholder: 'Mascot',
                value: '',
                paired: false
            },

            {
                name: 'type',
                required: true,
                label: 'Type',
                type: 'select',
                // placeholder: 'Mascot',
                options: [{label: 'Select type', value: ''}, {label: 'Pro', value: 'PRO'},{label: 'School', value: 'SCHOOL'},{label: 'Olympic', value: 'OLYMPIC'}],
                value: '',
                paired: false
            },
            
            {
                label: 'Background Color',
                type: 'color',
                name: 'logo_background_color',
                value: '',
                required: false,
                paired: false
            },
    
            {
                label: 'Logo',
                type: 'file',
                name: 'logo_url',
                value: '',
                required: false,
                paired: false
            }

        ]   
    }
])

const next = () => {
    currentPage.value++;
}

const submit = async () => {
    let payload = {
        ...organizationId ? { id: organizationId } : {}
    };
    submittable.value = true;
    data.value.forEach(block => {
        block.inputs.forEach(input => {
            if(!input.name) return;
            if(input.required && !input.value) return submittable.value = false;
            if(input.type === 'dropdown') payload[input.name] = input.value.id;
            else payload[input.name] = input.value;
        })
    })
    if(!submittable.value) return;
    // submit payload
    // console.log(payload)
    try {
        await create(payload);
    } catch(err) {
        alert('Error submitting team.')
        return console.log(err);
    }
    router.push('/organizations');
}

onMounted(async () => {
    if(!organizationId) return;
    let res = await getOrganization(organizationId);
    Object.keys(res).forEach(key => {
        // data.value[0].inputs.find(i => i.name === 'schoolId').value = res.school;
        // data.value[0].inputs.find(i => i.name === 'sportId').value = res.sport;
        let input = data.value[0].inputs.find(i => i.name === key);
        if(!input) return;
        // console.log('found input', input)
        input.value = res[key];
    })
})
</script>
<style type="text/css" scoped>

</style>