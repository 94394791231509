<template>
    
    <div id="color-bkg"></div>
	<div class="wrapper" :style="store.isMobile && store.windowHeight && `height: ${store.windowHeight}px`">
        <div class="x-wrap" @click="close">
            <img src="/svgs/x-vector.svg">
        </div>

		<div class="content">
            <br /><br /><br /><br /><br /><br /><br />
			<div class="card" style="display: flex">
				<div class="card-body" style="flex: 1">

					<h2 style="margin: 0">Update Header</h2>
					<br />
                    <Form 
                        :data="data"
                        :currentPage="0"
                        :submittable="submittable"
                        @submit="submit"
                    />
				</div>
			</div>
			<br />
		</div>
	</div>
</template>

<script setup>
import { useStore } from '@/store'
import { defineEmits, ref, onMounted } from 'vue';
import Form from '@/components/Form';
const emit = defineEmits(['close']);
import { getHeaderValues, setHeaderValues } from '@/services/admin-service';

const submittable = ref(true);
const data = ref([{
    title: 'General Info',
    inputs: [
        {
            name: 'name',
            required: true,
            label: 'Name',
            type: 'input',
            placeholder: 'Title',
            value: '',
            paired: false
        },
        {
            name: 'description',
            required: true,
            label: 'Description',
            type: 'input',
            placeholder: 'Description',
            value: '',
            paired: false
        },
		{
			name: 'eventOrLink',
			required: true,
			type: 'select',
			label: 'Event or Link?',
			options: [{label: 'Select', value: ''}, {label: 'Event', value: 'event'}, {label: 'Link', value: 'link'}],
			value: '',
			paired: false
		},
        {
            name: 'eventId',
            required: false,
            label: 'Event',
            type: 'dropdown',
            searchPath: '/search-events',
            placeholder: 'UC Irvine vs China',
            value: '',
			conditional: { name: 'eventOrLink', value: 'event' },
            paired: false
        },
		{
			name: 'link',
			required: false,
			label: 'Link',
			type: 'input',
			placeholder: 'google.com',
			value: '',
			conditional: { name: 'eventOrLink', value: 'link' },
			paired: false
		},
        {
            label: 'Header Image',
            type: 'file',
            name: 'thumbnail',
            value: '',
            required: true,
            paired: false
        }
    ]
}])

const submit = async () => {
    console.log(data.value)

    let payload = Object.assign({}, ...data.value[0].inputs.map(i => ({[i.name]: i.type === 'dropdown' ? i.value.id : i.value})))
    await setHeaderValues(payload)

	emit('close');
}

onMounted(async () => {
    let res = await getHeaderValues();
    Object.keys(res).forEach(key => {
		console.log('setting the keys', key);
        let input = data.value[0].inputs.find(i => i.name === key);
		if(!input) return;
		if(res[key]) input.value = res[key];
    })
})

const store = useStore();

const close = () => {
	emit('close')
};

// const toggle = () => {
//     emit('toggle')
// }

</script>

<style type="text/css" scoped>
#color-bkg {
	position: fixed;
	width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0,1);
    opacity: .2;
}

.x-wrap {
	position: fixed;
	left: .8rem;
	top: 1rem;
	background-color: #EBEBEB;
	display: inline-block;
	padding: .1rem .54rem;
	border-radius: 50%;
	transition: .1s ease all;
}

.x-wrap img {
	height: 8px;
	position: relative;
	top: -1px;
	left: 0px;
}

.x-wrap:hover {
	opacity: .7;
	cursor: pointer;
}

.wrapper {
	position: fixed;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #fff;
	height: 100vh;
	width: 100vw;
	z-index: 5;
	backdrop-filter: blur(10px);
    overflow-y:scroll;
}

.content {
	text-align: left;
}


.card {
	position: relative;
	background-color: #fff;
	min-height: 200px;
	width: 36rem;
	border-radius: 20px;
	color: #000;
}

.card-body {
	position: relative;
	padding: 1.5rem;
}


.card .btn {
	background: #000;
	color: #fff;
	border: none;
	width: 100%;
	border-radius: 5px;
	padding: .85rem;
	transition: .2s ease all;
}

.card h2 {
	/*padding-top: 1.5rem;*/
	margin: .5rem;
}

.btn p {
	padding: 0;
	margin: 0;
	font-size: .75rem;
	font-weight: 700;
}

.btn:hover {
	opacity: .9;
	cursor: pointer;
}

.form-control {
	background-color: #EBEBEB;
	width: 100%;
	box-sizing: border-box;
	border: none;
	padding: .85rem;
	color: #000;
	border-radius: 5px;
	outline: none !important;
}
</style>