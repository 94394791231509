<template>
    <!-- <VisibilityModal 
        v-if="showVisibilityModal"
        @close="toggleVisibilityModal"
        @toggle="toggleStatus" 
    /> -->

	<HeaderForm 
		v-if="showHeaderModal" 
        @close="toggleHeaderModal"
	/>
	<Nav />

	<div class="container" ref="element">
		<br /><br />
		<!-- to fix margin / padding issue, put in flex wrapper -->
		<div style="display: flex; flex-direction: 1;">
			<div class="card" style="flex: 1;">
				<div class="card-body" style="margin: 2rem 2.5rem; display: flex; justify-content: space-between; align-items: center;">
					<h1>Dashboard</h1>
                    <button class="btn" @click="toggleHeaderModal">Update Header</button>
				</div>
			</div>
		</div>

		<br />
		<p>Total time watched: {{ totalDuration.totalDuration }}</p>

		<br />
		<div>
			<!-- pills -->
			<div :class="`pill ${tab === 1 ? 'pill-active' : ''}`" @click="updateTab(1)">
				<span>Upcoming</span>
			</div>
			<div :class="`pill ${tab === 2 ? 'pill-active' : ''}`" @click="updateTab(2)">
				<span>Past</span>
			</div>
		</div>
		<br />
		<div style="display: flex; flex-direction: 1;">
		<div class="card" style="flex: 1">
			<div class="card-body" style="margin: 2rem 2.5rem;">
				<table class="table">
					<thead>
						<tr>
							<th>#</th>
							<th :class="'sort-column ' + (sort === 'name' ? 'active-sort' : '')" @click="updateSort('name')">Name</th>
							<th>Sport</th>
							<th>Channel</th>
							<th :class="'sort-column ' + (sort === 'timestamp' ? 'active-sort' : '')" @click="updateSort('timestamp')">Date</th>
							<th>Start Time</th>
							<!-- <th>Status</th> -->
							<!-- <th>Viewers</th> -->
							<!-- <th>Opt-Ins</th> -->
							<th>Featured</th>
							<th>Sport Featured</th>
							<th>Views</th>
							<th>Unique Views</th>
							<th>Live Link</th>
							<th>View</th>
							<th></th>
						</tr>
					</thead>
					<tbody>
						<tr 
							v-for="(event, key) in events"
							:key="key"
						>
							<td>{{key + 1}}</td>
							<td class="title">{{event.name}}</td>
							<td>{{event.sport.name}}</td>
							<td>{{ event.channel.name }}</td>
							<td>{{getDate(event.timestamp)}}</td>
							<td>{{getTime(event.timestamp)}}</td>
							<!-- <td @click="toggleVisibilityModal(event.id)">
								<button v-if="event.status === 'draft'" class="btn btn-draft">Draft</button>
								<button v-if="event.status === 'published'" class="btn btn-published">Published</button>
							</td> -->
							<!-- <td>{{event.viewers}}</td> -->
							<!-- <td>{{event.optins}}</td> -->
							<td>
								<label class="switch">
									<input 
										type="checkbox" 
										:checked="event.featured" 
										@change="() => {
											event.featured = !event.fetured;
											onToggle(event.id);
										}"
									>
									<span class="slider round"></span>
								</label>
							</td>
							<td>
								<label class="switch">
									<input 
										type="checkbox" 
										:checked="event.sport_featured" 
										@change="() => {
											event.sport_featured = !event.sport_featured;
											onToggle(event.id, true);
										}"
									>
									<span class="slider round"></span>
								</label>
							</td>
							<td>{{ event.views }}</td>
							<td>{{ event.uniqueViews }}</td>
							<td><a :href="`https://www.overnght.com/player/${event.id}`" target="_blank" class="btn">Link</a></td>
							<td><router-link class="btn" :to="`/events/${event.id}`">View</router-link></td>
							<td>
								<button class="btn btn-red" @click="onDelete(event)">
									<span v-if="event.confirmDelete">Confirm</span>
									<span v-if="!event.confirmDelete">Delete</span>
								</button
							></td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
		</div>
		<br /><br />
	</div>
</template>
<script setup>
import { onMounted, ref, onBeforeUnmount } from 'vue';
import Nav from '../Nav';
import { getEvents, deleteEvent, toggleFeatured } from '../../services/events-service';
import { getTotalDuration } from '../../services/admin-service';
// import VisibilityModal from './components/Visibility.vue'
// import tunecastService from '../../services/tunecast-service';
import HeaderForm from './components/HeaderForm';
const showHeaderModal = ref(false);
// const selectedTunecast = ref(0);
const totalDuration = ref(0);
const tab = ref(1);
const sort = ref('timestamp');
const page = ref(-1);

const getDate = (dateTime) => {
	let x = new Date(dateTime);
	return `${x.getMonth() + 1}/${x.getDate()}/${x.getFullYear()}`
}

const getTime = (dateTime) => {
	let x = new Date(dateTime);
	return `${x.getHours()}:${addPadding(x.getMinutes())}`
}

const addPadding = (num) => {
	return num < 10 ? '0' + num : num;
}

const toggleHeaderModal = () => {
	showHeaderModal.value = showHeaderModal.value ? false : true;
}

onBeforeUnmount(() => {
	window.removeEventListener('scroll', handleScroll);
})


// const toggleVisibilityModal = (id) => {
//     console.log('toggling?')
//     showVisibilityModal.value = showVisibilityModal.value ? false : true;
//     if(id) selectedTunecast.value = id;
// };

// const toggleStatus = async () => {
//     toggleVisibilityModal()
//     await tunecastService.updateStatus(selectedTunecast.value);
//     for(let j = 0; j < tunecasts.value.length; j++)
//         if(tunecasts.value[j].id === selectedTunecast.value) {
//             tunecasts.value[j].status = tunecasts.value[j].status === 'draft' ? 'published' : 'draft';
//             break;
//         }
//         selectedTunecast.value = 0;
// }

const events = ref([]);
const loading = ref(false);
const element = ref({});

const loadEvents = async () => {
	if(loading.value) return;
	loading.value = true;
	page.value++;
	let data = await getEvents({
		all: true,
		...tab.value === 1 ? {after: Date.now()} : {before: Date.now(), sortType: 'desc'},
		...sort.value ? {sort: sort.value} : {},
		page: page.value
	});
	loading.value = false;
	if(!data || !data.length) return page.value--;
	events.value = [...events.value, ...data];
}

const updateTab = n => {
	tab.value = n;
	events.value = [];
	page.value = -1;
	loadEvents();
}

const updateSort = key => {
	sort.value = key;
	events.value = [];
	page.value = -1;
	loadEvents();
}

onMounted(async () => {
	let data = await Promise.all([loadEvents(), getTotalDuration()]);
	totalDuration.value = data[1];
	window.addEventListener('scroll', handleScroll);
})

const onDelete = async event => {
	if(!event.confirmDelete) return event.confirmDelete = true;
	await deleteEvent(event.id);
	events.value.splice(events.value.indexOf(events.value.find(t => t.id === event.id)), 1);
}

const onToggle = async (eventId, sport) => {
	await toggleFeatured(eventId, sport);
}



const handleScroll = () => {
    const { scrollHeight } = element.value;  
	if (window.scrollY + window.innerHeight < scrollHeight - 150) return;
	loadEvents();
}
</script>
<style type="text/css" scoped>
.pills {
	display: flex;
	flex-direction: row;
}

.pill {
	background-color: #fff;
	padding: .8rem 1.5rem;
	border-radius: 5px;
	display: inline-block;
	margin-right: .5rem;
	transition: .2s ease all;
}

.pill:hover {
	opacity: .8;
	cursor: pointer;
}

.pill-active {
	background-color: #000;
	color: #fff;
}

.sort-column {
	transition: .2s ease all;
}

.sort-column:hover {
	opacity: .8;
	cursor: pointer;
}

.active-sort {
	font-weight: 700;
	color: #000;
}

/** SLIDER */
/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

/**
other stuff
 */
.btn {
    background: #000;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: .85rem 2rem;
    transition: .2s ease all;
}

.btn:hover {
    opacity: .9;
    cursor: pointer;
}
.btn-draft {
	color: #5e6a7a;
	font-size: 18px;
	font-weight: 600;
	background: rgba(94,106,122,.1);
}

.btn-red {
	background: red !important;
}

.btn-published {
	color: #14d147;
	font-size: 18px;
	font-weight: 600;
    background-color: rgba(20,209,71,.1);
}
</style>