<template>
    <div v-for="(dropdown, key) in dropdowns" :key="key">
    <Dropdown 
        :anchor="dropdownElements[dropdown.name]"
        :nodes="dropdownNodes[dropdown.name]"
        name-key="name"

        v-show="showDropdowns[dropdown.name]"
        @select="value => setDropdownValue(dropdown, value)"
    />
    </div>

    <div v-if="data && data[currentPage]" :class="data[currentPage].flex && 'form-flex'">
    <div class="form-group"	
        v-for="(input, key) in data[currentPage].inputs"
        :key="key"
    >
        <label v-if="(!input.conditional || (
                data[currentPage].inputs.find(i => i.name === input.conditional.name).value === input.conditional.value
            ))" :style="!submittable && input.required && !input.value ? 'color: red' : ''">{{input.label}}</label>
        <p 
            style="font-weight: 200; font-size: 15px" 
            v-if="input.description"
            v-html="input.description"
        >

        </p>

        <input 
            type="text" 
            :placeholder="input.placeholder"
            class="form-control"
            :disabled="input.disabled"
            :style="input.disabled && 'background-color: #777; color: #fff'"
            v-if="input.type === 'input' && (!input.conditional || (
                data[currentPage].inputs.find(i => i.name === input.conditional.name).value === input.conditional.value
            ))"
            v-model="input.value"
        >

        <input 
            type="date" 
            :placeholder="input.placeholder"
            class="form-control"
            v-if="input.type === 'date'"
            v-model="input.value"
        >

        <input 
            type="time" 
            :placeholder="input.placeholder"
            class="form-control"
            v-if="input.type === 'time'"
            v-model="input.value"
        >

        <input 
            type="color" 
            :placeholder="input.placeholder"
            class="form-control"
            style="padding: 0; height: 3.38rem;"
            v-if="input.type === 'color' && (!input.conditional || (
                data[currentPage].inputs.find(i => i.name === input.conditional.name).value === input.conditional.value
            ))"
            v-model="input.value"
        >

        <select
            v-if="input.type === 'select'"
            class="form-control"
            v-model="input.value"
            placeholder="Language"
        >
            <option
                v-for="(option, key) in input.options"
                :key="key"
                :value="option.value"
                :disabled="option.value === ''"
            >{{option.label}}</option>
        </select>

        <div v-if="input && input.type === 'dropdown' && (!input.conditional || (
                data[currentPage].inputs.find(i => i.name === input.conditional.name).value === input.conditional.value
            ))">
            <input 
                :ref="el => dropdownElements[input.name] = el"
                type="text" 
                class="form-control"
                :placeholder="input.placeholder" 
                v-model="dropdownModels[input.name]"

                @keyup="onQuery[input.name]"
                v-show="!input.value"
            >
            <p v-show="input.value" class="selected">
                {{input.value.name}} {{ input.value.timestamp && (input.value.timestamp).split('T')[0] }}
                <span style="font-weight: 700; cursor: pointer;" @click="clearValue(input)">x</span>
            </p>
        </div>

        <FileDrop 
            v-if="input.type === 'file'"
            :thumbnail="input.value"
            @select="file => input.version !== 'v2' && onSelectFile(input, file)"
            @select-v2="file => input.version === 'v2' && onSelectFileV2(input, file)"
        />

        <textarea 
            type="text" 
            :placeholder="input.placeholder"
            class="form-control"
            v-if="input.type === 'textbox'"
            :rows="input.rows"
            v-model="input.value"
        ></textarea>
    </div>
    </div>


    <br /><br /><br />

    <button 
        class="btn btn-next" 
        @click="emit('next')"
        v-if="currentPage !== data.length-1"
    >
        Next
    </button>
    <button 
        class="btn btn-next" 
        @click="emit('submit')"
        v-else
    >
        Submit
    </button>
</template>

<script setup>
// import { searchSports } from '../services/sports-service';
import Dropdown from './Dropdown.vue';
import FileDrop from './FileDrop';
import { onMounted, defineProps, defineEmits, ref, toRefs, watch } from 'vue';
import axios from 'axios';
import { BACKEND_URL } from '../services/config';

const props = defineProps(['data', 'currentPage', 'submittable']);
const emit = defineEmits(['submit', 'selectFile', 'next'])

const { data, currentPage, submittable } = toRefs(props);

const search = async (endpoint, query) => {
    const res = await axios.get(`${BACKEND_URL}${endpoint}${endpoint.includes('?') ? '&' : '?'}searchQuery=${query}`);
    return res.data;
}

/**
 * dropdown codes
 */
const dropdowns = ref([]);
const dropdownElements = ref({});
const showDropdowns = ref({});
const onQuery = ref({});
const dropdownModels = ref({});
const dropdownNodes = ref({});
    
const clearValue = input => {
    input.value = '';
}
const onSelectFile = (input, filePath) => {
	input.value = filePath;
}

const onSelectFileV2 = (input, data) => {
    input.value = data.thumbnail;
    input.filePath = data.filePath;
    input.mediaType = data.mediaType;
}

const setDropdownValue = (dropdown, value) => {
    for(let j = 0; j < data.value[currentPage.value].inputs.length; j++) {
        if(data.value[currentPage.value].inputs[j].name !== dropdown.name) continue;
        data.value[currentPage.value].inputs[j].value = value;

        dropdownModels.value[dropdown.name] = '';
        dropdownNodes.value[dropdown.name] = [];
        break;
    }
}

const updateDropdowns = () => {
    onQuery.value = {};
    dropdownModels.value = {};
    showDropdowns.value = {};

    // if(!data.value || !currentPage.value || !data.value[currentPage.value]) return;
    dropdowns.value = data.value[currentPage.value]
                        .inputs
                        .filter(input => {
                            return input.type === 'dropdown'
                        })
                        .map(d => {
                            d.nodes = [];
                            dropdownElements.value[d.name] = {};
                            dropdownNodes.value[d.name] = [];
                            showDropdowns.value[d.name] = false;
                            dropdownModels.value[d.name] = '';
                            onQuery.value[d.name] = async () => {
                                if(!dropdownModels.value[d.name]) {
                                    dropdownNodes.value[d.name] = [];
                                    return showDropdowns.value[d.name] = false;
                                }
                                else showDropdowns.value[d.name] = true;

                                let data = await search(d.searchPath, dropdownModels.value[d.name]);
                                if(data.length) dropdownNodes.value = {...dropdownNodes.value, [d.name]: data};
                                else dropdownNodes.value[d.name] = []
                            }

                            return {
                                ...d,
                                elemIndex: []
                            }
                        })


}

watch(currentPage, updateDropdowns);
onMounted(updateDropdowns)
</script>

<style scoped>

/**
 * FORM SECTION
 */
 .form-flex {
  display: flex;
  flex-wrap: wrap;
  column-gap: 1rem;
}

.form-flex .form-group {
	min-width: 49%;
}
.form-group label {
	font-weight: 500;
	display: block;
	margin: .8rem 0;
	font-size: 17px;
}

.form-control {
	background: #EBEBEB;
	border: none;
	display: block;
	padding: 1rem 1.5rem;
	font-size: 16px;
	width: 100%;
	box-sizing: border-box;
}

.selected {
    margin: 0;
	background: #EBEBEB;
    color: #000;
    font-weight: 700;
	border: none;
	display: block;
	padding: 1rem 1.5rem;
	font-size: 16px;
	width: 100%;
	box-sizing: border-box;
}

.form-control::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #5A5A5A;
  opacity: 1; /* Firefox */
}

.form-group {
	padding-bottom: .5rem;
}

.btn-next {
	font-weight: 200;
	background-color: #000;
	color: #fff;
	padding: .5rem 3.5rem;
	position: absolute;
	right: -1rem;
	bottom: -.25rem;
	border: none;
	font-size: 16px;
	border-radius: 3px;
	transition: .1s ease opacity;
}

.btn-next:hover {
	cursor: pointer;
	opacity: .8;
}
</style>