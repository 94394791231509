<template>
    <div>
        <Nav />
        <div class="container">
            <br /><br />
            <!-- to fix margin / padding issue, put in flex wrapper -->
            <div style="display: flex; flex-direction: 1;">
                <div class="card" style="flex: 1;">
                    <div class="card-body" style="margin: 2rem 2.5rem">
                        <h1 v-if="!athleteId">New Athlete</h1>
                        <h1 v-else>Edit Athlete</h1>
                    </div>
                </div>
            </div>
    
            <br />
            <div style="display: flex">
            <div class="card" style="flex: 1">
                <div class="card-body">
                    <Form 
                        :data="data"
                        :currentPage="currentPage"
                        :submittable="submittable"
                        @next="next"
                        @submit="submit"
                    />
                </div>
            </div>
            </div>

            <br /><br />
        </div>
    </div>
</template>

<script setup>
import { useRoute } from 'vue-router';
// import router from '@/router';
import router from '@/router';
import Nav from '@/components/Nav';
import { ref, onMounted } from 'vue';
// import FileDrop from './FileDrop';
import Form from '@/components/Form.vue';
import { getAthlete, create } from '../../../services/athletes-service';

const currentPage = ref(0);
const submittable = ref(true);

const route = useRoute();
const athleteId = route.params.athleteId;

console.log(athleteId, 'onmounted?', onMounted);

onMounted(async () => {
    if(!athleteId) return;
    let res = await getAthlete(athleteId);
    let _athlete = res.athlete;
    Object.keys(_athlete).forEach(key => {
        data.value[0].inputs.find(i => i.name === 'teamId').value = _athlete.team;
        data.value[0].inputs.find(i => i.name === 'sportId').value = _athlete.team.sport;
        let input = data.value[0].inputs.find(i => i.name === key);
        if(!input) return;
        console.log(key, input)
        input.value = _athlete[key];
    })
})


const submit = async () => {
    let payload = {
        ...athleteId ? { id: athleteId } : {}
    };
    submittable.value = true;
    data.value.forEach(block => {
        block.inputs.forEach(input => {
            if(!input.name) return;
            if(input.required && !input.value) return submittable.value = false;
            if(input.type === 'dropdown') payload[input.name] = input.value.id;
            else payload[input.name] = input.value;
        })
    })
    if(!submittable.value) return;
    try {
        await create(payload);
    } catch(err) {
        alert('Error submitting athlete.')
        return console.log(err);
    }
    let teamId = data.value[0].inputs.find(i => i.name === 'teamId').value.id;
    router.push(`/teams/${teamId}/roster`);
}

/**
 * paired defaults to true
 * if paired defaults to true, if false it does not connect for labels
 */

 const data = ref([
    {
        title: 'General Info',
        flex: true,
        inputs: [
            {
                name: 'name',
                required: true,
                label: 'Name',
                title: 'Name', // if different from label
                type: 'input',
                placeholder: 'Title',
                value: '',
                paired: false
            },
            {
                name: 'hometown',
                required: true,
                label: 'Hometown',
                type: 'input',
                placeholder: 'Albany, New York',
                value: '',
                paired: false
            },

            {
                name: 'year',
                required: true,
                label: 'year',
                type: 'input',
                placeholder: 'Freshman',
                value: '',
                paired: false
            },

            // {
            //     name: 'birthday',
            //     required: true,
            //     label: 'Birthday',
            //     type: 'date',
            //     placeholder: '',
            //     value: '',
            //     paired: false
            // },

            {
                name: 'sportId',
                required: true,
                label: 'Sport',
                type: 'dropdown',
                searchPath: '/search-sports',
                placeholder: 'Men\'s Water Polo',
                value: '',
                paired: false
            },

            {
                name: 'teamId',
                required: true,
                label: 'Team',
                type: 'dropdown',
                searchPath: '/search-teams',
                placeholder: 'UC Irvine',
                value: '',
                paired: false
            },


            {
                name: 'position',
                required: true,
                label: 'Position',
                type: 'input',
                placeholder: 'Center',
                value: '',
                paired: false
            },

            {
                name: 'height',
                required: true,
                label: 'Height',
                type: 'input',
                placeholder: '6\'4"',
                value: '',
                paired: false
            },


            {
                name: 'number',
                required: true,
                label: 'Number',
                type: 'input',
                placeholder: '16',
                value: '',
                paired: false
            },


            {
                label: 'Headshot',
                type: 'file',
                name: 'avatar',
                value: '',
                required: false,
                paired: false
            },

            {
                label: 'Personality Pic',
                type: 'file',
                name: 'personality_image',
                value: '',
                required: false,
                paired: false
            }
        ]   
    }
])
</script>