<template>
    <!-- <CreateModal 
        v-if="showCreateModal" 
        @close="toggleCreateModal"
    /> -->
	<Nav />
	
	<div class="container">
		<br /><br />
		<!-- to fix margin / padding issue, put in flex wrapper -->
		<div style="display: flex; flex-direction: 1;">
			<div class="card" style="flex: 1;">
				<div class="card-body" style="margin: 2rem 2.5rem; display: flex; justify-content: space-between; align-items: center;">
					<h1>Channels</h1>

					<!-- @click="toggleCreateModal" -->
                    <router-link class="btn" to="/channels/new" >Create Channel</router-link>
				</div>
			</div>
		</div>

		<br />
		<div style="display: flex; flex-direction: 1;">
		<div class="card" style="flex: 1">
			<div class="card-body" style="margin: 2rem 2.5rem;">
				<table class="table">
					<thead>
						<tr>
							<th>#</th>
							<th>Name</th>
							<th>Arn</th>
							<th>View</th>
							<th>Delete</th>
						</tr>
					</thead>
					<tbody>
						<tr 
							v-for="(channel, key) in channels"
							:key="key"
						>
							<td>{{key + 1}}</td>
							<td>{{ channel.name }}</td>
							<td>{{ channel.arn }}</td>
							<td><router-link :to="`/channels/edit/${channel.id}`" class="btn">View</router-link></td>
							<td><button class="btn btn-red" @click="onDelete(channel)">
								<span v-if="channel.confirmDelete">Confirm</span>
								<span v-if="!channel.confirmDelete">Delete</span>
							</button></td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
		</div>
		<br /><br />
	</div>
</template>
<script setup>
import { onMounted, ref } from 'vue';
import Nav from '../Nav';
// import CreateModal from './components/Create';
import { getChannels, deleteChannel } from '../../services/channels-service';

const channels = ref([]);
// const showCreateModal = ref(false);

const onDelete = async channel => {
	if(!channel.confirmDelete) return channel.confirmDelete = true;
	await deleteChannel(channel.id);
	channels.value.splice(channels.value.indexOf(channels.value.find(t => t.id === channel.id)), 1);
}

// const toggleCreateModal = () => showCreateModal.value = !showCreateModal.value;

onMounted(async () => {
	// let data = await fetchTunecasts() ;
	let data = await getChannels();
	channels.value = data;
})
</script>
<style type="text/css" scoped>
.btn {
    background: #000;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: .85rem 2rem;
    transition: .2s ease all;
}

.btn-red {
	background: red !important;
}

.btn:hover {
    opacity: .9;
    cursor: pointer;
}

</style>