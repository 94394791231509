<template>
    <div>
        <Nav />
        <div class="container">
            <br /><br />
            <!-- to fix margin / padding issue, put in flex wrapper -->
            <div style="display: flex; flex-direction: 1;">
                <div class="card" style="flex: 1;">
                    <div class="card-body" style="margin: 2rem 2.5rem">
                        <h1 v-if="!athleteId">New Media</h1>
                        <h1 v-else>Edit Media</h1>
                    </div>
                </div>
            </div>
    
            <br />
            <div style="display: flex">
            <div class="card" style="flex: 1">
                <div class="card-body">
                    <Form 
                        :data="data"
                        :currentPage="currentPage"
                        :submittable="submittable"
                        @next="next"
                        @submit="submit"
                    />
                </div>
            </div>
            </div>

            <br /><br />
        </div>
    </div>
</template>

<script setup>
import { useRoute } from 'vue-router';
// import router from '@/router';
import router from '@/router';
import Nav from '@/components/Nav';
import { ref, onMounted } from 'vue';
// import FileDrop from './FileDrop';
import Form from '@/components/Form.vue';
import { createMedia } from '../../../services/athletes-service';

const currentPage = ref(0);
const submittable = ref(true);

const route = useRoute();
const athleteId = route.params.athleteId;

onMounted(async () => {
    // if(!athleteId) return;
    // let res = await getAthlete(athleteId);
    // let _athlete = res.athlete;
    // Object.keys(_athlete).forEach(key => {
    //     data.value[0].inputs.find(i => i.name === 'teamId').value = _athlete.team;
    //     data.value[0].inputs.find(i => i.name === 'sportId').value = _athlete.team.sport;
    //     let input = data.value[0].inputs.find(i => i.name === key);
    //     if(!input) return;
    //     console.log(key, input)
    //     input.value = _athlete[key];
    // })
})


const submit = async () => {
    let payload = {
        // ...athleteId ? { id: athleteId } : {}
    };

    submittable.value = true;
    data.value.forEach(block => {
        block.inputs.forEach(input => {
            console.log('input?', input.name, input);
            if(!input.name) return;
            if(input.required && !input.value) return submittable.value = false;
            if(input.name === 'media') {
                payload.thumbnail = input.value;
                payload.filePath = input.filePath;
                payload.mediaType = input.mediaType;
            }
            else if(input.type === 'dropdown') payload[input.name] = input.value.id;
            else payload[input.name] = input.value;
        })
    })
    if(!submittable.value) return;
    try {
        await createMedia(payload);
    } catch(err) {
        alert('Error submitting athlete.')
        return console.log(err);
    }
    // let teamId = data.value[0].inputs.find(i => i.name === 'teamId').value.id;
    router.push(`/media`);
}

/**
 * paired defaults to true
 * if paired defaults to true, if false it does not connect for labels
 */

 const data = ref([
    {
        title: 'General Info',
        flex: true,
        inputs: [

            {
                name: 'eventId',
                required: true,
                label: 'Event',
                type: 'dropdown',
                searchPath: '/search-events',
                placeholder: 'UCLA vs Stanford',
                value: '',
                paired: false
            },

            {
                name: 'athleteId',
                required: true,
                label: 'Athlete',
                type: 'dropdown',
                searchPath: '/search-athletes',
                placeholder: 'John Snow',
                value: '',
                paired: false
            },

            {
                name: 'name',
                required: true,
                description: `
                    \${ATHLETE} - athlete name and hyperlink
                    <br/>
                    \${AGAINST} - opposing team and hyperlink`,
                label: 'Name',
                title: 'Name', // if different from label
                type: 'input',
                placeholder: '${ATHLETE} scores goal against ${AGAINST}',
                value: '',
                paired: false
            },

            {
                label: 'Media',
                type: 'file',
                name: 'media',
                filePath: '',
                mediaType: '',
                value: '',
                version: 'v2',
                required: true,
                paired: false
            },
        ]   
    }
])
</script>