<template>
<nav>
	<div class="nav-wrapper">
		<h1><router-link to="/">Overnght</router-link></h1>

		<ul>
			<li><u><router-link to="/" style="color: #fff">Dashboard</router-link></u></li>
			<li><router-link to="/sports" style="color: #fff; text-decoration: none;">Sports</router-link></li>
			<li><router-link to="/organizations" style="color: #fff; text-decoration: none;">Organizations</router-link></li>
			<li><router-link to="/teams" style="color: #fff; text-decoration: none;">Teams</router-link></li>
			<li><router-link to="/channels" style="color: #fff; text-decoration: none;">Channels</router-link></li>
			<li><router-link to="/users" style="color: #fff; text-decoration: none;">Users</router-link></li>
			<!-- <li>Users</li> -->
			<!-- <li>Analytics</li> -->
			<li><router-link class="btn" to="/create">Create Event</router-link></li>
		</ul>
	</div>
</nav>
</template>
<style type="text/css">
/**
 * NAV
 *
 */
nav {
	background-color: #000;
	color: #fff;
	height: 4rem;
	margin: 0;
}

.nav-wrapper {
	position: relative;
	padding: 0 2rem;
	height: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
}

.nav-wrapper h1 a {
	font-size: 28px;
	color: #fff;
	text-decoration: none;
}

nav ul li {
	display: inline;
	font-weight: 200;
	margin-right: 2rem;
}

nav ul {
	margin: 0;
	margin-left: 2rem;
	display: flex;
	align-items: center;
}

.btn {
	background-color: #fff;
	padding: .5rem 1.5rem;
	color: #000;
	border-radius: 2px;
	font-size: 14px;
	border: none;
	text-decoration: none!important;
}
</style>