<template>
    <Nav />

    <div class="container">
        <br /><br />
        <div v-if="user && user.firstName">
            <h2 style="display: flex; align-items: center;">{{ user.firstName + ' ' + user.lastName }} <span style="
                padding: .25rem .4rem;
                background-color: #999;
                border-radius: 5px;
                color: #fff;
                font-weight: 200;
                font-size: 12px;
                margin-left: .5rem;
            ">f7hM-281jaja</span></h2>
            <br />
            <p>Total Time Watched: {{ timeWatched }}</p>
            <p>Signed up {{ user.createdAt }}</p>
            <p>{{ user.firstName + ' ' + user.lastName }} agreed to <a href="https://overnght.com/terms" target="_blank">Terms of Service</a> ✅ on {{ user.createdAt.toString().split('T')[0] }}</p>
            <br />
            <h3>Watch History</h3>
            <table style="width: 100%; text-align: left;">
                <thead>
                    <th>Name</th>
                    <th>Time</th>
                    <th>Session Duration</th>
                </thead>
                <tbody>
                    <tr 
                        v-for="(session, key) in sessions"
                        :key="key"
                    >
                        <td>{{ session.eventName }}</td>
                        <td>{{ formatDateString(session.sessionTime) }}</td>
                        <td>{{ msToTime(session.sessionDuration) }}</td>
                    </tr>
                </tbody>
            </table>

            <h3>IPs</h3>
            <table style="width: 100%; text-align: left;">
                <thead>
                    <th>Address</th>
                    <th>Country</th>
                    <th>Date</th>
                </thead>
                <tbody>
                    <tr 
                        v-for="(ip, key) in ips"
                        :key="key"
                    >
                        <td>{{ ip.ipAddress }}</td>
                        <td>{{ ip.ipCountryCode }}</td>
                        <td>{{ ip.date }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script setup>
import Nav from '@/components/Nav';
import { onMounted, ref } from 'vue';
import { getUser } from '@/services/users-service';
import { useRoute } from 'vue-router';

const user = ref({});
const timeWatched = ref('');
const sessions = ref([]);
const ips = ref([]);

const route = useRoute();
const userId = route.params.userId;
onMounted(async () => {
    if(!userId) return;
    let data = await getUser(userId);
    console.log(user);
    user.value = data.user;
    timeWatched.value = msToTime(data.totalTimeWatched);
    sessions.value = data.sessions;
    ips.value = data.ips;
})


function msToTime(ms) {
  // Calculate hours, minutes, and seconds
  const seconds = Math.floor((ms / 1000) % 60);
  const minutes = Math.floor((ms / (1000 * 60)) % 60);
  const hours = Math.floor(ms / (1000 * 60 * 60));

  // Build the formatted time string
  let formattedTime = '';

  if (hours > 0) {
    formattedTime += `${hours} hour${hours > 1 ? 's' : ''}`;
  }

  if (minutes > 0) {
    if (formattedTime !== '') {
      formattedTime += ', ';
    }
    formattedTime += `${minutes} minute${minutes > 1 ? 's' : ''}`;
  }

  if (seconds > 0) {
    if (formattedTime !== '') {
      formattedTime += ', and ';
    }
    formattedTime += `${seconds} second${seconds > 1 ? 's' : ''}`;
  }

  return formattedTime;
}

function formatDateString(dateString) {
  const date = new Date(dateString);

  const dateOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };

  const timeOptions = {
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    timeZoneName: 'short',
  };

  const formattedDate = date.toLocaleDateString('en-US', dateOptions);
  const formattedTime = date.toLocaleTimeString('en-US', timeOptions);

  return `${formattedDate} ${formattedTime}`;
}

// // // Example usage:
// const milliseconds = 3665000; // 1 hour, 1 minute, and 5 seconds
// const formattedTime = msToTime(milliseconds);
// console.log(formattedTime); // Output: "1 hour, 1 minute, and 5 seconds"

</script>