<template>
    <Head>
        <link href="https://cdnjs.cloudflare.com/ajax/libs/video.js/7.14.3/video-js.css" rel="stylesheet">
        <link href="https://unpkg.com/@silvermine/videojs-quality-selector/dist/css/quality-selector.css" rel="stylesheet">
    </Head>
    <div>
        <Nav />
        <div class="container">
            <br /><br />
            <!-- to fix margin / padding issue, put in flex wrapper -->
            <div style="display: flex; flex-direction: 1;">
                <div class="card" style="flex: 1;">
                    <div class="card-body" style="margin: 2rem 2.5rem">
                        <h1 v-if="!channelId">Create a Channel</h1>
                        <h1 v-else>Edit Channel</h1>
                    </div>
                </div>
            </div>
    
            <br />
            <div style="display: flex; flex-direction: 1; column-gap: 1rem;">
                <div class="card" style="width: 20rem">
                    <div class="card-body list-items">
                        <div id="item-line"></div>

                        <div
                            v-for="(page, key) in data"
                            :class="currentPage === key && 'active'"
                            :key="key"
                        >
                            <h3 @click="selectPage(key)">

                                <span class="list-item-circle"></span>
                                <span>{{page.title}}</span>
                            </h3>

                            <div v-if="currentPage === key">
                                <ul>
                                    <li 
                                        v-for="(input, key) in filterInputs(page.inputs)"
                                        :key="key"
                                        :style="input.completed && `color: #9D53F1`"
                                    >{{input.title || input.label}}</li>
                                </ul>
                            </div>
                        </div>

                    </div>
                </div>


                <div class="card" style="flex: 1">
                    <div class="card-body">
                        <Form 
                            :data="data"
                            :currentPage="currentPage"
                            :submittable="submittable"
                            @next="next"
                            @submit="submit"
                        />
                    </div>
                </div>

            </div>

            <br />

            <div style="display: flex;" v-if="channelId">
                <div class="card" style="width: 49%; min-height: 500px; margin-right: 1rem;">
                    <div class="card-body">
                        <h2>Sessions</h2>

                        <table style="width: 100%; text-align: left;">
                            <thead>
                                <th>Stream ID</th>
                                <th>Start time</th>
                                <th>Duration</th>
                            </thead>
                            <tbody>
                                <tr 
                                    v-for="(session, key) in sessions"
                                    :key="key"
                                >
                                    <td>{{ session.streamId }}</td>
                                    <td>{{ session.startTime }}</td>
                                    <td>{{ millisecondsToTime(new Date(session.endTime).getTime() - (new Date(session.startTime).getTime())) }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div class="video-container">
                    <video 
                        ref="channel1" 
                        id="ivselem" 
                        data-test="ivselasdmtest" 
                        class="video-js vjs-4-3 vjs-big-play-centered"
                        data-setup='{"fluid": true}' 
                        height="300px" 
                        controls autoplay playsinline
                    ></video>
                </div>
            </div>

            <br />
        </div>
    </div>
</template>
<style>
.video-container {
    flex: 1
}
</style>

<style type="text/css" scoped>

/**
 * SIDEBAR
 */

#item-line {
	position: absolute;
	width: 3.5px;
	height: 100%;
	left: .52rem;
	background-color: #000;
}

.list-items {
	position: relative;
	/*height: 200px;*/
	overflow: hidden;
}

.list-items h3 {
	font-size: 17px;
	display: flex; 
	align-items: center;
}

.list-items h3 span:nth-child(2) {
	transition: .1s ease opacity;
}

.list-items h3:hover { cursor: pointer; }

.list-items h3:hover span:nth-child(2) { opacity: .7; }

.list-items h3.past {
	color: #9D53F1;
}

.list-items h3.past .list-item-circle {
	background: #9D53F1;
}

.list-items .active h3 {
	color: #5E16B0;
	font-size: 20px;
}

.list-items .active h3 .list-item-circle {
	background: #5E16B0;
}

.list-items ul {
	padding-bottom: .25px;
}
.list-items ul li {
	list-style-type: none;
	color: #5A5A5A;
	font-weight: 200;
	margin-bottom: .75rem;
	font-size: 15px;
}

.list-item-circle {
	position: relative;
	z-index: 5;
	display: inline-block; 
	width: 1.3rem; 
	height: 1.3rem; 
	background-color: #000; 
	border-radius: 54%;
	margin-right: .8rem;
}

</style>
<script>
function millisecondsToTime(duration) {
    let seconds = Math.floor((duration / 1000) % 60),
        minutes = Math.floor((duration / (1000 * 60)) % 60),
        hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

    hours = (hours < 10) ? "0" + hours : hours;
    minutes = (minutes < 10) ? "0" + minutes : minutes;
    seconds = (seconds < 10) ? "0" + seconds : seconds;

    return hours + ":" + minutes + ":" + seconds;
}
</script>
<script setup>

import { useRoute } from 'vue-router';
import router from '@/router';
import Nav from '@/components/Nav';
import { ref, onMounted, watch } from 'vue';
import videojs from 'video.js';
// import FileDrop from './FileDrop';
import Form from '@/components/Form.vue';
import { create, getChannel } from '@/services/channels-service';

const currentPage = ref(0);
const submittable = ref(true);

const channel1 = ref(null);
const sessions = ref(null);

const route = useRoute();
const channelId = route.params.channelId;

/**
 * paired defaults to true
 * if paired defaults to true, if false it does not connect for labels
 */
const data = ref([
    {
        title: 'General Info',
        flex: true,
        inputs: [
            {
                name: 'name',
                required: true,
                label: 'Name',
                type: 'input',
                placeholder: 'Title',
                value: '',
                paired: false
            },
            {
                name: 'url',
                required: false,
                label: 'Url',
                type: 'input',
                placeholder: 'url',
                value: '',
                paired: false,
                disabled: true
            },
            {
                name: 'ingest_endpoint',
                required: false,
                label: 'Ingest Endpoint',
                value: '',
                type: 'input',
                paired: false,
                disabled: true
            },
            {
                name: 'stream_key',
                required: false,
                label: 'Stream Key',
                value: '',
                type: 'input',
                paired: false,
                disabled: true
            },
            {
                name: 'subchannelCount',
                required: true,
                label: 'Number of Subchannels',
                type: 'input',
                placeholder: 'Enter number of subchannels',
                value: '',
                paired: false
            }
        ]
    },
    {
        title: 'Camera Angles',
        flex: true,
        inputs: []
    }
]);

let _setting = false;

watch(() => data.value[0].inputs.find(i => i.name === 'subchannelCount').value, (newCount) => {
    if(_setting) return;
    console.log('enw value?', newCount, isNaN(newCount))
    if (!newCount || isNaN(newCount)) return;
    const count = parseInt(newCount);


    const cameraAngleInputs = data.value[1].inputs;
    // cameraAngleInputs.splice(0, cameraAngleInputs.length); // Clear existing inputs

    for (let i = 0; i < count; i++) {
        console.log('pushing');
        cameraAngleInputs.push({
            name: `cameraAngleName_${i + 1}`,
            label: `Camera Angle ${i + 1} Name`,
            type: 'input',
            placeholder: `Camera Angle ${i + 1} Name`,
            value: '',
            paired: false
        });
        cameraAngleInputs.push({
            name: `cameraAngleUrl_${i + 1}`,
            label: `Camera Angle ${i + 1} URL`,
            type: 'input',
            hide_label: true,
            // placeholder: `Camera Angle ${i + 1} URL`,
            value: '',
            disabled: true,
            paired: false
        });
    }
});


const next = () => {
    currentPage.value++;
}

const submit = async () => {
    let payload = {
        ...channelId ? { id: channelId } : {}
    };
    submittable.value = true;
    
    data.value.forEach(block => {
        block.inputs.forEach(input => {
            if (!input.name) return;
            if (input.required && !input.value) return submittable.value = false;
            if (input.type === 'dropdown') payload[input.name] = input.value.id;
            else payload[input.name] = input.value;
        });
    });

    if (!submittable.value) return;

    // Collect subchannel/camera angle data
    const subchannelCount = parseInt(payload.subchannelCount || 0);
    const subchannels = [];
    for (let i = 1; i <= subchannelCount; i++) {
        subchannels.push({
            name: payload[`cameraAngleName_${i}`],
            url: payload[`cameraAngleUrl_${i}`]
        });
    }

    payload.subchannels = subchannels;

    try {
        await create(payload);
    } catch (err) {
        alert('Error submitting channel.');
        return console.log(err);
    }

    router.push('/channels');
}


onMounted(async () => {
    if(!channelId) return;
    let res = await getChannel(channelId);
    console.log(res)
    let _channel = res.channel;
    const cameraAngleInputs = data.value[1].inputs;
    data.value[0].inputs.find(i => i.name === 'subchannelCount').value = _channel.subchannels ? _channel.subchannels.length : 0;


    _setting = true;
    _channel.subchannels.forEach((sub, i) => {
        console.log('pushing');
        cameraAngleInputs.push({
            name: `cameraAngleName_${i + 1}`,
            label: `Camera Angle ${i + 1} Name`,
            type: 'input',
            placeholder: `Camera Angle ${i + 1} Name`,
            value: sub.name,
            paired: false
        });
        cameraAngleInputs.push({
            name: `cameraAngleUrl_${i + 1}`,
            label: `Camera Angle ${i + 1} Key`,
            type: 'input',
            hide_label: true,
            value: sub.stream_key,
            disabled: true,
            paired: false
        });
    })

    setTimeout(() => _setting = false, 1000)

    Object.keys(_channel).forEach(key => {
        // data.value[0].inputs.find(i => i.name === 'schoolId').value = res.school;
        // data.value[0].inputs.find(i => i.name === 'sportId').value = res.sport;
        let input = data.value[0].inputs.find(i => i.name === key);
        if(!input) return;
        // console.log('found input', input)
        input.value = _channel[key];
    })


    sessions.value = res.sessions;
    console.log('GOT SESSIONS', res.sessions)
    if(_channel.url) {

        const waitForIVS = () => new Promise((res) => {
            let _interval = setInterval(() => {
                console.log('waiting...', channel1.value)
                if(!channel1.value) return;
                clearInterval(_interval);
                return setTimeout(res, 1000);
            }, 100);
        })

        let player;

        // Define a custom property and its type
        await new Promise((res) => setTimeout(res, 500));
        await waitForIVS();

        console.log('got ivs!', channel1.value)

        const { registerIVSTech, registerIVSQualityPlugin } = await import('amazon-ivs-player');

        try {
            registerIVSTech(videojs, {
                wasmWorker: '/assets/amazon-ivs-wasmworker.min.js',
                wasmBinary: '/assets/amazon-ivs-wasmworker.min.wasm'
            });

            registerIVSQualityPlugin(videojs);
        } catch(err) {
            console.log('got an error registering ivs tech', err);
        }

        ivsInit(channel1.value, _channel.url);


    }
})

function ivsInit(val, url) {
    let player = videojs(val, {
        techOrder: ["AmazonIVS"]
    }, () => {
        player.src(url)
        player.ready(() => {
            player.play();
        });
    });

    let hasBeenCalled = false;

    const retryFunction = (err) => {
        console.log('got error...')
        setTimeout(() => {
            if(hasBeenCalled) return;
            console.log('retrying...')
            player.off('error', retryFunction)
            hasBeenCalled = true;
            ivsInit(val, url)
        }, 6000)
    }

    player.on('error', retryFunction)

}


const selectPage = index => {
	currentPage.value = index;
}

const filterInputs = inputs => {
	let newInputs = inputs.map((i, key) => {
        if(i.hide_label) return;
		if(data.value[currentPage.value].flex && i.paired !== false && key % 2 !== 0) return;

		/**
		 * if this is a flex form...
		 * we check the current inputs value and the next one
		 * because they are paired
		 */
		return ({
			...i,
			completed: data.value[currentPage.value].flex && i.paired !== false ? (!!i.value && (key + 1 === inputs.length || !!inputs[key + 1].value)) : !!i.value
		})
	})

	return newInputs.filter((i, key) => {
		return !data.value[currentPage.value].flex || (i && i.paired === false) ||  key % 2 === 0;
	});
}
</script>
<style type="text/css" scoped>

</style>