// import axios from "axios";
import { BACKEND_URL } from "./config";
import {axiosInstance} from './auth-service';

export async function create(opts) {
  const res = await axiosInstance.post(`${BACKEND_URL}/events`, { ...opts });
  return res.data;
}

export async function getEvents(options) {
  let queryStr = '?';
  for(let prop in options)
    queryStr += `${prop}=${options[prop]}&`

  const res = await axiosInstance.get(`${BACKEND_URL}/events${queryStr}`);
  return res.data;
}

export async function getEvent(id) {
  const res = await axiosInstance.get(`${BACKEND_URL}/events/${id}`);
  return res.data;
}


export async function deleteEvent(id) {
  const res = await axiosInstance.delete(`${BACKEND_URL}/events/${id}`);
  return res.data;
}


export async function toggleFeatured(id, sport) {
  const res = sport ? await axiosInstance.post(`${BACKEND_URL}/events/${id}/sport-featured`) : await axiosInstance.post(`${BACKEND_URL}/events/${id}/featured`);
  return res.data;
}