<template>
  <router-view></router-view>
</template>

<script setup>
// import Create from './components/Create';
import router from './router';
import { loginWithToken } from './services/auth-service';
import { onBeforeMount } from 'vue';

onBeforeMount(async () => {
  let data = await loginWithToken();
  if(!data.success) router.push('/login');
})
</script>

<style>
* {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  /*border: 0;*/
  /*padding: 0;*/
  /*margin: 0;*/
}
body {
  background: #f3f4f6;
  margin: 0;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


.container {
  padding: 0 4rem;
}

.card {
  background-color: #fff;
  border-radius: 10px;
}

.card-body {
  margin: 2.5rem;
  position: relative;
}

h1,h2,h5 {
  margin: 0;
}

.table {
	width: 100%;
	border-collapse: collapse; 
}

.table th {
	text-align: left;
	color: #919bac;
	font-weight: 200;
	padding-bottom: .75rem;
}

.table .title {
	font-size: 20px;
	font-weight: 500;
}


.table td {
	padding: .75rem 0;
	border-top: 1px solid rgba(0,0,0,.1);
}


.table .btn {
	font-weight: 200;
	background-color: #000;
	color: #fff;
	padding: .35rem 2rem;
	border: none;
	font-size: 16px;
	border-radius: 3px;
	transition: .1s ease opacity;
}

.table .btn:hover {
	cursor: pointer;
	opacity: .8;
}

.form-group label {
	font-weight: 500;
	display: block;
	margin: .8rem 0;
	font-size: 17px;
}

.form-control {
	background: #EBEBEB;
	border: none;
	display: block;
	padding: 1rem 1.5rem;
	font-size: 16px;
	width: 100%;
	box-sizing: border-box;
}
</style>
