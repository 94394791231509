import { ref } from 'vue';
import { defineStore } from 'pinia'

// what is computed? 

export const useStore = defineStore('store', () => {


	const jwt = ref('');
	const state = ref(0);


	return { state, jwt }
})