import {axiosInstance} from './auth-service';
import { BACKEND_URL } from "./config";
// import cookies from "vue-cookies";

// if (cookies.get("user"))
//   axios.defaults.headers.common["Authorization"] = cookies.get("user").token;

export async function create(opts) {
  const res = await axiosInstance.post(`${BACKEND_URL}/channels`, { ...opts });
  return res.data;
}

export async function getChannel(channelId) {
  const res = await axiosInstance.get(`${BACKEND_URL}/channels/${channelId}`);
  return res.data;
}
export async function getChannels() {
  const res = await axiosInstance.get(`${BACKEND_URL}/channels`);
  return res.data;
}
export async function deleteChannel(id) {
  const res = await axiosInstance.delete(`${BACKEND_URL}/channels/${id}`);
  return res.data;
}

export async function searchChannels(query) {
  const res = await axiosInstance.get(`${BACKEND_URL}/search-channels?searchQuery=${query}`);
  return res.data;
}

