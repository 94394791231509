<template>
<div>
	<Nav />
	<div class="container">
		<br /><br />
		<!-- to fix margin / padding issue, put in flex wrapper -->
		<div style="display: flex; flex-direction: 1;">
			<div class="card" style="flex: 1;">
				<div class="card-body" style="margin: 2rem 2.5rem">
					<h1 v-if="!eventId">Create an Event</h1>
                    <h1 v-else>Edit Event</h1>
				</div>
			</div>
		</div>

		<br />
		<div style="display: flex; flex-direction: 1; column-gap: 1rem;">
			<div class="card" style="width: 20rem">
				<div class="card-body list-items">
					<div id="item-line"></div>

					<div
						v-for="(page, key) in data"
						:class="currentPage === key && 'active'"
						:key="key"
					>
						<h3 @click="selectPage(key)">

							<span class="list-item-circle"></span>
							<span>{{page.title}}</span>
						</h3>

						<div v-if="currentPage === key">
							<ul>
								<li 
									v-for="(input, key) in filterInputs(page.inputs)"
									:key="key"
									:style="input.completed && `color: #9D53F1`"
								>{{input.title || input.label}}</li>
							</ul>
						</div>
					</div>

				</div>
			</div>


			<div class="card" style="flex: 1">
				<div class="card-body">
					<Form 
						:data="data"
						:currentPage="currentPage"
						:submittable="submittable"
						@next="next"
						@submit="submit"
					/>
				</div>
			</div>

		</div>
		<br /><br />
	</div>
</div>
</template>
<script setup>
import router from '@/router';
import Nav from './Nav';
import { ref, onMounted, watch } from 'vue';
// import FileDrop from './FileDrop';
import Form from './Form.vue';
import { useRoute } from 'vue-router';


import { create, getEvent } from '@/services/events-service';

const currentPage = ref(0);

const submittable = ref(true);

const route = useRoute();
const eventId = route.params.eventId;

/**
 * paired defaults to true
 * if paired defaults to true, if false it does not connect for labels
 */

const data = ref([
	{
		title: 'General Info',
		flex: true,
		inputs: [
			{
				name: 'name',
				required: true,
				label: 'Name (Internal Only)',
				title: 'Name', // if different from label
				type: 'input',
				placeholder: 'Title',
				value: '',
				paired: false
			},
			{
				name: 'sportId',
				required: true,
				label: 'Sport',
				type: 'dropdown',
				searchPath: '/search-sports',
				placeholder: 'Waterpolo',
				value: '',
				paired: false
			},


			{
				name: 'team1Id',
				required: true,
				label: 'Team 1',
				type: 'dropdown',
				searchPath: '',
				placeholder: 'UCLA',
				value: '',
				paired: false
			},


			{
				name: 'team2Id',
				required: true,
				label: 'Team 2',
				type: 'dropdown',
				searchPath: '',
				// searchPath: '/search-teams',
				placeholder: 'UC Irvine',
				value: '',
				paired: false
			},

			{
				label: 'Thumbnail',
				type: 'file',
				name: 'thumbnail',
				value: '',
				paired: false
			}


		]
	},

	{
		title: 'Scheduling',
		flex: true,
		inputs: [
			{
				label: 'Start Date',
				name: "start_date",
				type: 'date',
				placeholder: 'mm/dd/yyyy',
				required: true,
				value: ''
			},
			{
				label: 'Start Time',
				name: "start_time",
				type: 'time',
				// placeholder: 'mm/dd/yyyy',
				required: true,
				value: ''
			},


			{
				label: 'Timezone',
				name: "timezone",
				required: true,
				type: 'select',
				value: '',
				paired: false,
				options: [
					{label: 'Select Timezone', value: ''},
					{label: 'US Pacific Time (PST/PDT)', value: 'America/Los_Angeles'}, 
					{label: 'US Eastern Time (EST/EDT)', value: 'America/New_York'},
					{label: 'US Central Time (CST/CDT)', value: 'America/Chicago'},
					{label: 'US Mountain Time (MST/MDT)', value: 'America/Denver'},
					{label: 'Japanese Standard Time (JST)', value: 'Asia/Tokyo'}
				]
			},
		]
		
	},

	{
		title: 'Streaming Setup',
		flex: true,
		inputs: [
			{
				name: 'channelId',
				required: true,
				label: 'Channel',
				type: 'dropdown',
				searchPath: '/search-channels',
				placeholder: 'Channel 1',
				value: '',
				paired: false
			},
			{
				name: 'is_free',
				label: 'Free Event?',
				type: 'select',
				options: [{label: 'No', value: false}, {label: 'Yes', value: true}],
				value: false
			},
			{
				name: 'ended',
				label: 'Ended',
				type: 'select',
				options: [{label: 'No', value: false}, {label: 'Yes', value: true}],
				value: false
			}
		]
	}
])

watch(
    () => data.value[0].inputs[1].value, // The sportId input
    (newSportId) => {
		console.log('new sport id!', newSportId?.id);
        const searchPath = `/search-teams?sportId=${newSportId?.id}`; // Check if newSportId exists before accessing id
        data.value[0].inputs.find(input => input.name === 'team1Id').searchPath = searchPath;
        data.value[0].inputs.find(input => input.name === 'team2Id').searchPath = searchPath;
    }
);

const next = () => {
	currentPage.value++;
}

const submit = async () => {
	let payload = {
        ...eventId ? { id: eventId } : {}
    };
	submittable.value = true;
	data.value.forEach(block => {
		block.inputs.forEach(input => {
			if(!input.name) return;
			if(input.required && !input.value) return submittable.value = false;
			if(input.type === 'dropdown') payload[input.name] = input.value.id;
            else payload[input.name] = input.value;
		})
	})

	if(payload.team1Id === payload.team2Id && !payload.thumbnail) return alert('Thumbnail is required for this event.')
	if(!submittable.value) return console.log('no submit');
	// submit payload
	await create(payload);
	router.push('/');
}

const selectPage = index => {
	currentPage.value = index;
}

const filterInputs = inputs => {
	let newInputs = inputs.map((i, key) => {
		if(data.value[currentPage.value].flex && i.paired !== false && key % 2 !== 0) return;

		/**
		 * if this is a flex form...
		 * we check the current inputs value and the next one
		 * because they are paired
		 */
		return ({
			...i,
			completed: data.value[currentPage.value].flex && i.paired !== false ? (!!i.value && (key + 1 === inputs.length || !!inputs[key + 1].value)) : !!i.value
		})
	})

	return newInputs.filter((i, key) => {
		return !data.value[currentPage.value].flex || (i && i.paired === false) ||  key % 2 === 0;
	});
}

// const onSelectFile = (input, filePath) => {
// 	input.value = filePath;
// }

// const isFinished = (inputs) => {
// 	const _inputs = inputs.map(i => i.completed);
// 	if(_inputs.indexOf(false) > -1) return false;
// 	return true;
// }

onMounted(async () => {
    if(!eventId) return;
    let res = await getEvent(eventId);
    Object.keys(res).forEach(key => {
        data.value[2].inputs.find(i => i.name === 'channelId').value = res.channel;
        data.value[0].inputs.find(i => i.name === 'sportId').value = res.sport;
		if(res.teams && res.teams.length) {
			if(res.teams[0])
				data.value[0].inputs.find(i => i.name === 'team1Id').value = res.teams[0];

			if(res.teams[1])
				data.value[0].inputs.find(i => i.name === 'team2Id').value = res.teams[1];
		}
        let input;
		input = data.value[0].inputs.find(i => i.name === key);
		if(!input) input = data.value[1].inputs.find(i => i.name === key);
		if(!input) input = data.value[2].inputs.find(i => i.name === key);
        if(!input) return;
        input.value = res[key];
    })
})


</script>
<style type="text/css" scoped>

/**
 * SIDEBAR
 */

#item-line {
	position: absolute;
	width: 3.5px;
	height: 100%;
	left: .52rem;
	background-color: #000;
}

.list-items {
	position: relative;
	/*height: 200px;*/
	overflow: hidden;
}

.list-items h3 {
	font-size: 17px;
	display: flex; 
	align-items: center;
}

.list-items h3 span:nth-child(2) {
	transition: .1s ease opacity;
}

.list-items h3:hover { cursor: pointer; }

.list-items h3:hover span:nth-child(2) { opacity: .7; }

.list-items h3.past {
	color: #9D53F1;
}

.list-items h3.past .list-item-circle {
	background: #9D53F1;
}

.list-items .active h3 {
	color: #5E16B0;
	font-size: 20px;
}

.list-items .active h3 .list-item-circle {
	background: #5E16B0;
}

.list-items ul {
	padding-bottom: .25px;
}
.list-items ul li {
	list-style-type: none;
	color: #5A5A5A;
	font-weight: 200;
	margin-bottom: .75rem;
	font-size: 15px;
}

.list-item-circle {
	position: relative;
	z-index: 5;
	display: inline-block; 
	width: 1.3rem; 
	height: 1.3rem; 
	background-color: #000; 
	border-radius: 54%;
	margin-right: .8rem;
}

</style>