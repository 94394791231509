<template>
    <!-- <VisibilityModal 
        v-if="showVisibilityModal"
        @close="toggleVisibilityModal"
        @toggle="toggleStatus" 
    /> -->
	<Nav />
	
	<div class="container">
		<br /><br />
		<!-- to fix margin / padding issue, put in flex wrapper -->
		<div style="display: flex; flex-direction: 1;">
			<div class="card" style="flex: 1;">
				<div class="card-body" style="margin: 2rem 2.5rem; display: flex; justify-content: space-between; align-items: center;">
					<h1>Teams</h1>

                    <router-link class="btn" to="/teams/new">Create Team</router-link>
				</div>
			</div>
		</div>

		<br />
		<div style="display: flex; flex-direction: 1;">
		<div class="card" style="flex: 1">
			<div class="card-body" style="margin: 2rem 2.5rem;">
				<input v-model="queryInput" @keyup="loadTeams" type="text" class="form-control" placeholder="Search...">
				<br />
				<br />
				<table class="table">
					<thead>
						<tr>
							<th>#</th>
							<th>Name</th>
                            <th>Sport</th>
							<th>Organization</th>
							<th>Events</th>
							<th>Roster</th>
							<th>Edit</th>
						</tr>
					</thead>
					<tbody>
						<tr 
							v-for="(team, key) in teams"
							:key="key"
						>
							<td>{{key + 1}}</td>
							<td class="title">{{team.name}}</td>
							<td>{{ team.sport.name }}</td>
							<td>{{ team.organization.name }}</td>
							<td>{{ team.events.length }}</td>
							<td><router-link class="btn" :to="`/teams/${team.id}/roster`">View</router-link></td>
							<td><router-link class="btn" :to="`/teams/edit/${team.id}`">Edit</router-link></td>
							<td><button class="btn btn-red" @click="onDelete(team)">
								<span v-if="team.confirmDelete">Confirm</span>
								<span v-if="!team.confirmDelete">Delete</span>
							</button></td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
		</div>
		<br /><br />
	</div>
</template>
<script setup>
import { onMounted, ref } from 'vue';
import Nav from '../Nav';
import { getTeams, deleteTeam } from '@/services/teams-service.js';

const teams = ref([]);
const queryInput = ref('');

const onDelete = async team => {
	if(!team.confirmDelete) return team.confirmDelete = true;
	await deleteTeam(team.id);
	teams.value.splice(teams.value.indexOf(teams.value.find(t => t.id === team.id)), 1);
}

let loading = false;
let prevQuery = null;
const loadTeams = async () => {
	if(prevQuery === queryInput.value) return;
	prevQuery = queryInput.value;
	if(loading) return;
	loading = true;
	teams.value = [];
	let data = await getTeams(queryInput.value);
	teams.value = data;
	loading = false;
}

onMounted(async () => {
	await loadTeams();
})
</script>
<style type="text/css" scoped>
.btn {
    background: #000;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: .85rem 2rem;
    transition: .2s ease all;
}

.btn-red {
    background: red!important;
}

.btn:hover {
    opacity: .9;
    cursor: pointer;
}
</style>