<template>
    <!-- <VisibilityModal 
        v-if="showVisibilityModal"
        @close="toggleVisibilityModal"
        @toggle="toggleStatus" 
    /> -->
	<Nav />
	
	<div class="container">
		<br /><br />
		<!-- to fix margin / padding issue, put in flex wrapper -->
		<div style="display: flex; flex-direction: 1;">
			<div class="card" style="flex: 1;">
				<div class="card-body" style="margin: 2rem 2.5rem; display: flex; justify-content: space-between; align-items: center;">
					<h1>Users</h1>

                    <!-- <router-link class="btn" to="/teams/new">Create Team</router-link> -->
				</div>
			</div>
		</div>

		<br />
		<div style="display: flex; flex-direction: 1;">
		<div class="card" style="flex: 1">
			<div class="card-body" style="margin: 2rem 2.5rem;">
				<input v-model="queryInput" @keyup="loadUsers" type="text" class="form-control" placeholder="Search...">
				<br />
				<br />
				<table class="table">
					<thead>
						<tr>
							<th>#</th>
							<th>Name</th>
							<th>Email</th>
                            <!-- <th>Sport</th>
							<th>Organization</th>
							<th>Events</th> -->
							<th>Active</th>
							<th>Status</th>
							<th>Plan</th>
							<th>View</th>
							<th></th>
						</tr>
					</thead>
					<tbody>
						<tr 
							v-for="(user, key) in users"
							:key="key"
						>
							<td>{{key + 1}}</td>
							<td>{{user.firstName + ' ' + user.lastName}}</td>
							<td style="cursor: pointer;" @click="toggleEmail(user)">{{ user.showEmail ? user.email : hideEmail(user.email) }}</td>
							<!--<td>{{ user.organization.name }}</td>
							<td>{{ userteam.events.length }}</td> -->
							<td>
								<div v-if="user.subscription && user.subscription.length && user.subscription[user.subscription.length - 1].active" class="btn btn-published">Active</div>
								<div v-else class="btn btn-draft">Inactive</div>
							</td>
							<td>
								<div v-if="user.subscription && user.subscription.length" class="btn btn-draft">{{user.subscription[user.subscription.length - 1].status}}</div>
							</td>
							<td>
								<span v-if="user.subscription && user.subscription.length">{{ user.subscription[0].plan }}</span>
								<span v-else>-</span>
							</td>
							<td><router-link class="btn" :to="`/users/${user.id}`">View</router-link></td>
							<td><button class="btn btn-red" @click="onDelete(user)">
								<span v-if="user.confirmDelete">Confirm</span>
								<span v-if="!user.confirmDelete">Delete</span>
							</button></td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
		</div>
		<br /><br />
	</div>
</template>
<script setup>
import { onMounted, ref } from 'vue';
import Nav from '../Nav';
import { getUsers } from '@/services/users-service.js';

const users = ref([]);
const queryInput = ref('');

const onDelete = async () => {
	// if(!team.confirmDelete) return team.confirmDelete = true;
	// await deleteTeam(team.id);
	// teams.value.splice(teams.value.indexOf(teams.value.find(t => t.id === team.id)), 1);
}

let loading = false;
let prevQuery = null;
const loadUsers = async () => {
	if(prevQuery === queryInput.value) return;
	prevQuery = queryInput.value;
	if(loading) return;
	loading = true;
	users.value = [];
	let data = await getUsers(queryInput.value);
	users.value = data.map(u => ({...u, showEmail: false}));
	loading = false;
}

onMounted(async () => {
	await loadUsers();
})

const toggleEmail = user => user.showEmail = !user.showEmail;

const hideEmail = email => email.charAt(0) + '*******' + email.charAt(email.length - 1);
</script>
<style type="text/css" scoped>
.btn {
    background: #000;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: .85rem 1rem;
    transition: .2s ease all;
}

.btn-red {
    background: red!important;
}

.btn:hover {
    opacity: .9;
    cursor: pointer;
}

.btn-draft {
	color: #5e6a7a;
	font-size: 18px;
	font-weight: 600;
	background: rgba(94,106,122,.1);
	width: 3rem!important;
}

.btn-published {
	color: #14d147!important;
	font-size: 18px;
	font-weight: 600;
    background-color: rgba(20,209,71,.1)!important;
	width: 3rem!important;
}

</style>