import {axiosInstance} from './auth-service';
import { BACKEND_URL } from "./config";
// import cookies from "vue-cookies";

// if (cookies.get("user"))
//   axios.defaults.headers.common["Authorization"] = cookies.get("user").token;

export async function create(opts) {
  const res = await axiosInstance.post(`${BACKEND_URL}/teams`, { ...opts });
  return res.data;
}

export async function getTeams(query) {
  const res = await axiosInstance.get(`${BACKEND_URL}/teams${query ? '?queryStr=' + query : ''}`);
  return res.data;
}

export async function getTeam(id) {
  const res = await axiosInstance.get(`${BACKEND_URL}/teams/${id}`);
  return res.data;
}

export async function getRoster(id) {
  const res = await axiosInstance.get(`${BACKEND_URL}/roster/${id}`);
  return res.data;
}

export async function deleteTeam(id) {
  const res = await axiosInstance.delete(`${BACKEND_URL}/teams/${id}`);
  return res.data;
}

export async function searchTeams(query) {
  const res = await axiosInstance.get(`${BACKEND_URL}/search-teams?searchQuery=${query}`);
  return res.data;
}

