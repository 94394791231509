import {axiosInstance} from './auth-service';
import { BACKEND_URL } from "./config";
// import cookies from "vue-cookies";

// if (cookies.get("user"))
//   axios.defaults.headers.common["Authorization"] = cookies.get("user").token;

export async function create(opts) {
  const res = await axiosInstance.post(`${BACKEND_URL}/media`, { ...opts });
  return res.data;
}

export async function getMedias(query) {
  const res = await axiosInstance.get(`${BACKEND_URL}/media${query ? '?queryStr=' + query : ''}`);
  return res.data;
}

export async function getMedia(id) {
  const res = await axiosInstance.get(`${BACKEND_URL}/media/${id}`);
  return res.data;
}

export async function createMedia(opts) {
  const res = await axiosInstance.post(`${BACKEND_URL}/media`, { ...opts });
  return res.data;
}

export async function deleteMedia(id) {
    const res = await axiosInstance.delete(`${BACKEND_URL}/media/${id}`);
    return res.data;
  }
  
