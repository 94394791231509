<template>
    <CreateModal 
        v-if="showCreateModal" 
        @close="toggleCreateModal"
    />
	<Nav />
	
	<div class="container">
		<br /><br />
		<!-- to fix margin / padding issue, put in flex wrapper -->
		<div style="display: flex; flex-direction: 1;">
			<div class="card" style="flex: 1;">
				<div class="card-body" style="margin: 2rem 2.5rem; display: flex; justify-content: space-between; align-items: center;">
					<h1>Sports</h1>

                    <button class="btn" @click="toggleCreateModal">Create Sport</button>
				</div>
			</div>
		</div>

		<br />
		<div style="display: flex; flex-direction: 1;">
		<div class="card" style="flex: 1">
			<div class="card-body" style="margin: 2rem 2.5rem;">
				<table class="table">
					<thead>
						<tr>
							<th>#</th>
							<th>Name</th>
							<th>Tag</th>
							<th>Teams</th>
							<th>View</th>
						</tr>
					</thead>
					<tbody>
						<tr 
							v-for="(sport, key) in sports"
							:key="key"
						>
							<td>{{key + 1}}</td>
							<td>{{ sport.name }}</td>
							<td>{{ sport.tag }}</td>
							<td>{{  sport.teamCount }}</td>
							<td><button class="btn">View</button></td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
		</div>
		<br /><br />
	</div>
</template>
<script setup>
import { onMounted, ref } from 'vue';
import Nav from '../Nav';
import CreateModal from './components/Create';
import { getSports } from '../../services/sports-service';

const sports = ref([]);
const showCreateModal = ref(false);

const toggleCreateModal = () => showCreateModal.value = !showCreateModal.value;

onMounted(async () => {
	// let data = await fetchTunecasts() ;
	let data = await getSports();
	sports.value = data;
})
</script>
<style type="text/css" scoped>
.btn {
    background: #000;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: .85rem 2rem;
    transition: .2s ease all;
}

.btn:hover {
    opacity: .9;
    cursor: pointer;
}

</style>