// import axios from "axios";
import { BACKEND_URL } from "./config";
import {axiosInstance} from './auth-service';

export async function create(opts) {
  const res = await axiosInstance.post(`${BACKEND_URL}/users`, { ...opts });
  return res.data;
}

export async function getUsers(query) {
  const res = await axiosInstance.get(`${BACKEND_URL}/users${query ? '?queryStr=' + query : ''}`);
  return res.data;
}

export async function getUser(id) {
  const res = await axiosInstance.get(`${BACKEND_URL}/users/${id}`);
  return res.data;
}
