import {axiosInstance} from './auth-service';
import { BACKEND_URL } from "./config";
// import cookies from "vue-cookies";

// if (cookies.get("user"))
//   axios.defaults.headers.common["Authorization"] = cookies.get("user").token;

export async function create(opts) {
  const res = await axiosInstance.post(`${BACKEND_URL}/sports`, { ...opts });
  return res.data;
}

export async function getSports() {
  const res = await axiosInstance.get(`${BACKEND_URL}/sports`);
  return res.data;
}

export async function searchSports(query) {
  const res = await axiosInstance.get(`${BACKEND_URL}/search-sports?searchQuery=${query}`);
  return res.data;
}

