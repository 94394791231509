import {axiosInstance} from './auth-service';
import { BACKEND_URL } from "./config";
// import cookies from "vue-cookies";

// if (cookies.get("user"))
//   axios.defaults.headers.common["Authorization"] = cookies.get("user").token;

export async function create(opts) {
  const res = await axiosInstance.post(`${BACKEND_URL}/athletes`, { ...opts });
  return res.data;
}

export async function getAthletes(query) {
  const res = await axiosInstance.get(`${BACKEND_URL}/athletes${query ? '?queryStr=' + query : ''}`);
  return res.data;
}

export async function getAthlete(id) {
  const res = await axiosInstance.get(`${BACKEND_URL}/athlete/${id}`);
  return res.data;
}

export async function deleteAthlete(id) {
  const res = await axiosInstance.delete(`${BACKEND_URL}/athlete/${id}`);
  return res.data;
}

export async function searchAthletes(query) {
  const res = await axiosInstance.get(`${BACKEND_URL}/search-athletes?searchQuery=${query}`);
  return res.data;
}

export async function createMedia(opts) {
  const res = await axiosInstance.post(`${BACKEND_URL}/media`, { ...opts });
  return res.data;
}

