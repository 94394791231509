<template>
	<Nav />
	
	<div class="container">
		<br /><br />
		<!-- to fix margin / padding issue, put in flex wrapper -->
		<div style="display: flex; flex-direction: 1;">
			<div class="card" style="flex: 1;">
				<div class="card-body" style="margin: 2rem 2.5rem; display: flex; justify-content: space-between; align-items: center;">
					<h1>Roster</h1>

                    <!-- <router-link class="btn" to="/teams/new">Create Team</router-link> -->
				</div>
			</div>
		</div>

		<br />
		<div style="display: flex; flex-direction: 1;">
		<div class="card" style="flex: 1">
			<div class="card-body" style="margin: 2rem 2.5rem;">
				<input v-model="queryInput" @keyup="loadTeams" type="text" class="form-control" placeholder="Search...">
				<br />
				<br />
				<table class="table">
					<thead>
						<tr>
							<th>#</th>
							<th>Name</th>
                            <th>Position</th>
                            <th>Year</th>
							<!-- <th>Organization</th> -->
							<!-- <th>Events</th> -->
							<th>View</th>
						</tr>
					</thead>
					<tbody>
						<tr 
							v-for="(athlete, key) in athletes"
							:key="key"
						>
							<td>{{key + 1}}</td>
							<td class="title">{{athlete.name}}</td>
							<td>{{ athlete.position }}</td>
							<td>{{ athlete.year }}</td>
							<td><router-link class="btn" :to="`/athletes/${athlete.id}`">View</router-link></td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
		</div>
		<br /><br />
	</div>
</template>
<script setup>
import { onBeforeMount, ref } from 'vue';
import Nav from '../../Nav';
import { useRoute } from 'vue-router';
import { getRoster } from '@/services/teams-service.js';

const team = ref({});
const athletes = ref([])

const route = useRoute();
const teamId = route.params.teamId;

onBeforeMount(async () => {
    let data = await getRoster(teamId);
    team.value = data;
    athletes.value = data.athletes;
})
</script>
<style type="text/css" scoped>
.btn {
    background: #000;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: .85rem 2rem;
    transition: .2s ease all;
}

.btn-red {
    background: red!important;
}

.btn:hover {
    opacity: .9;
    cursor: pointer;
}
</style>