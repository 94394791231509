<template>
	<Nav />
	
	<div class="container">
		<br /><br />
		<!-- to fix margin / padding issue, put in flex wrapper -->
		<div style="display: flex; flex-direction: 1;">
			<div class="card" style="flex: 1;">
				<div class="card-body" style="margin: 2rem 2.5rem; display: flex; justify-content: space-between; align-items: center;">
					<h1>Media</h1>

                    <router-link class="btn" to="/media/new">Create Media</router-link>
				</div>
			</div>
		</div>

		<br />
		<div style="display: flex; flex-direction: 1;">
		<div class="card" style="flex: 1">
			<div class="card-body" style="margin: 2rem 2.5rem;">
				<!-- <input v-model="queryInput" @keyup="loadMedia" type="text" class="form-control" placeholder="Search..."> -->
				<!-- <br /> -->
				<br />
				<table class="table">
					<thead>
						<tr>
							<th>Name</th>
                            <th>Sport</th>
                            <th>Type</th>
                            <th>Athlete</th>
							<th>Event</th>
                            <th>View</th>
							<!-- <th>Edit</th> -->
                            <th></th>
						</tr>
					</thead>
					<tbody>
						<tr 
							v-for="(media, key) in medias"
							:key="key"
						>
							<td>{{media.name}}</td>
							<td>{{ media.event.sport.name }}</td>
                            <td>{{ media.mediaType }}</td>
							<td>{{ media.athlete.name }}</td>
							<td>{{ media.event.name }}</td>
                            <td><a class="btn" :href="`https://www.overnght.com/media/${media.id}`" target="_blank">View</a></td>
							<td><button class="btn btn-red" @click="onDelete(media)">
								<span v-if="media.confirmDelete">Confirm</span>
								<span v-if="!media.confirmDelete">Delete</span>
							</button></td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
		</div>
		<br /><br />
	</div>
</template>
<script setup>
import { onMounted, ref } from 'vue';
import Nav from '../Nav';
import { getMedias, deleteMedia } from '@/services/media-service.js';

const medias = ref([]);
// const queryInput = ref('');

const onDelete = async media => {
	if(!media.confirmDelete) return media.confirmDelete = true;
	await deleteMedia(media.id);
	medias.value.splice(medias.value.indexOf(medias.value.find(t => t.id === media.id)), 1);
}

// let loading = false;
// let prevQuery = null;
const loadMedia = async () => {
	// if(prevQuery === queryInput.value) return;
	// prevQuery = queryInput.value;
	// if(loading) return;
	// loading = true;
	// teams.value = [];
	// let data = await getTeams(queryInput.value);
	// teams.value = data;
	// loading = false;

    let data = await getMedias();
    medias.value = data;
}

onMounted(async () => {
	await loadMedia();
})
</script>
<style type="text/css" scoped>
.btn {
    background: #000;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: .85rem 2rem;
    transition: .2s ease all;
}

.btn-red {
    background: red!important;
}

.btn:hover {
    opacity: .9;
    cursor: pointer;
}
</style>