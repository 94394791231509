<script setup>
import {  defineProps, defineEmits, ref, onMounted, toRefs, watch } from 'vue';
const position = ref({});

const emit = defineEmits(['select'])
const props = defineProps([ 'anchor', 'nodes' ])

const { anchor, nodes } = toRefs(props);

const selectNode = (node) => {
  emit('select', node);
}

function getPosition() {
    if(!anchor.value || !anchor.value.getBoundingClientRect) return;
    const boundingRect = anchor.value.getBoundingClientRect();
    position.value = {
        left: boundingRect.left,
        top: boundingRect.bottom + 1,
        width: boundingRect.right - boundingRect.left
    }
}

// hooks + listeners
onMounted(() => {
    window.addEventListener('resize', getPosition);
    window.addEventListener('scroll', getPosition);
    setInterval(getPosition, 1000);
});
watch(anchor, getPosition);
window.addEventListener('resize', getPosition)
</script>

<template>
  <div 
    class="dropdown"
    :style="`top: ${position.top}px; left: ${position.left}px; width: ${position.width}px;`"
  >
    <p class="dropdown-option"
      v-for="(n,k) in nodes"
      :key="k"
      @click="selectNode(n)"
    >
        {{n.name}} {{ n.timestamp && (n.timestamp).split('T')[0] }}
    </p>
  </div>
</template>

<style scoped>
.dropdown {
  z-index: 10000000;
  /* width: 12rem; */
  max-height: 10rem;
  background-color: #fff;
  box-shadow: 0 0 20px rgba(0,0,0,.2);
  border-radius: .2rem;
  position: fixed;
  overflow: scroll;
}

.dropdown-option {
  padding: .5rem 1rem;
  color: #333;
}

.dropdown-option:hover, .selected {
  background-color: rgba(0,0,0,.05);
  cursor: pointer;
}
</style>
