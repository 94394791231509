<template>
    <!-- <CreateModal 
        v-if="showCreateModal" 
        @close="toggleCreateModal"
    /> -->
	<Nav />
	
	<div class="container">
		<br /><br />
		<!-- to fix margin / padding issue, put in flex wrapper -->
		<div style="display: flex; flex-direction: 1;">
			<div class="card" style="flex: 1;">
				<div class="card-body" style="margin: 2rem 2.5rem; display: flex; justify-content: space-between; align-items: center;">
					<h1>Organizations</h1>

					<!-- @click="toggleCreateModal" -->
                    <router-link class="btn" to="/organizations/new" >Create Organization</router-link>
				</div>
			</div>
		</div>

		<br />
		<div style="display: flex; flex-direction: 1;">
		<div class="card" style="flex: 1">
			<div class="card-body" style="margin: 2rem 2.5rem;">
				<input v-model="queryInput" @keyup="loadOrganizations" type="text" class="form-control" placeholder="Search...">
				<br />
				<br />
				<table class="table">
					<thead>
						<tr>
							<th>#</th>
							<th>Name</th>
							<th>Type</th>
							<th>Teams</th>
							<th>View</th>
							<th>Delete</th>
						</tr>
					</thead>
					<tbody>
						<tr 
							v-for="(organization, key) in organizations"
							:key="key"
						>
							<td>{{key + 1}}</td>
							<td>{{ organization.name }}</td>
							<td>{{ organization.type.toLowerCase() }}</td>
							<td>{{ organization.teamCount }}</td>
							<td><router-link :to="`/organizations/edit/${organization.id}`" class="btn">View</router-link></td>
							<td><button class="btn btn-red" @click="onDelete(organization)">
								<span v-if="organization.confirmDelete">Confirm</span>
								<span v-if="!organization.confirmDelete">Delete</span>
							</button></td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
		</div>
		<br /><br />
	</div>
</template>
<script setup>
import { onMounted, ref } from 'vue';
import Nav from '../Nav';
// import CreateModal from './components/Create';
import { getOrganizations, deleteOrganization } from '../../services/organizations-service';

const queryInput = ref('');
const organizations = ref([]);
// const showCreateModal = ref(false);

const onDelete = async organization => {
	if(!organization.confirmDelete) return organization.confirmDelete = true;
	await deleteOrganization(organization.id);
	organizations.value.splice(organizations.value.indexOf(organizations.value.find(t => t.id === organization.id)), 1);
}

// const toggleCreateModal = () => showCreateModal.value = !showCreateModal.value;

let loading = false;
let prevQuery = null;
const loadOrganizations = async () => {
	console.log(prevQuery, queryInput.value)
	if(prevQuery === queryInput.value) return;
	prevQuery = queryInput.value;
	if(loading) return;
	loading = true;
	organizations.value = [];
	let data = await getOrganizations(queryInput.value);
	organizations.value = data;
	loading = false;
}

onMounted(async () => {
	await loadOrganizations();
})
</script>
<style type="text/css" scoped>
.btn {
    background: #000;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: .85rem 2rem;
    transition: .2s ease all;
}

.btn-red {
	background: red !important;
}

.btn:hover {
    opacity: .9;
    cursor: pointer;
}

</style>