import { createRouter, createWebHistory } from 'vue-router';
import { getAuthToken } from '@/services/auth-service';

// 1. Define route components.
// These can be imported from other files
// const Home = { template: '<div>Home</div>' }
import Login from '@/components/Login';
import Dashboard from '@/components/Dashboard';
import Sports from '@/components/Sports';
import Teams from '@/components/Teams';
import Media from '@/components/Media';
import CreateTeam from '@/components/Teams/pages/Create';
import Create from '@/components/Create';

import Organizations from '@/components/Organizations';
import CreateOrganization from '@/components/Organizations/pages/Create';
import Channels from '@/components/Channels';
import CreateChannel from '@/components/Channels/pages/Create';

import Users from '@/components/Users';
import User from '@/components/User';

import Roster from '@/components/Teams/pages/Roster';

import CreateAthlete from '@/components/Athlete/pages/Create';
import CreateMedia from '@/components/Media/pages/Create';

// 2. Define some routes
// Each route should map to a component.
// We'll talk about nested routes later.
const routes = [
  { path: '/', meta: {requiresAuth: true}, component: Dashboard },
  { path: '/events/:eventId', meta: {requiresAuth: true}, component: Create },
  { path: '/login', component: Login },
  { path: '/create', meta: {requiresAuth: true}, component: Create },
  { path: '/sports', meta: {requiresAuth: true}, component: Sports },
  { path: '/organizations', meta: {requiresAuth: true}, component: Organizations },
  { path: '/organizations/new', meta: {requiresAuth: true}, component: CreateOrganization },
  { path: '/organizations/edit/:organizationId', meta: {requiresAuth: true}, component: CreateOrganization },
  { path: '/teams', meta: {requiresAuth: true}, component: Teams },
  { path: '/teams/new', meta: {requiresAuth: true}, component: CreateTeam },
  { path: '/teams/edit/:teamId', meta: {requiresAuth: true}, component: CreateTeam },
  { path: '/teams/:teamId/roster', meta: {requiresAuth: true}, component: Roster },
  { path: '/athletes/new', meta: {requiresAuth: true}, component: CreateAthlete},
  { path: '/athletes/:athleteId', meta: {requiresAuth: true}, component: CreateAthlete},


  { path: '/channels', meta: {requiresAuth: true}, component: Channels },
  { path: '/channels/new', meta: {requiresAuth: true}, component: CreateChannel },
  { path: '/channels/edit/:channelId', meta: {requiresAuth: true}, component: CreateChannel },
  { path: '/users', meta: {requiresAuth: true}, component: Users },
  { path: '/users/:userId', meta: {requiresAuth: true}, component: User },

  { path: '/media', meta: {requiresAuth: true}, component: Media },
  { path: '/media/new', meta: {requiresAuth: true}, component: CreateMedia },
]

// 3. Create the router instance and pass the `routes` option
// You can pass in additional options here, but let's
// keep it simple for now.
console.log('the router is...?')
const router = createRouter({
  // 4. Provide the history implementation to use. We are using the hash history for simplicity here.
  history: createWebHistory(),
  routes, // short for `routes: routes`
})

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const isAuthenticated = !!getAuthToken();

  if (requiresAuth && !isAuthenticated) {
    next('/login'); // Redirect to login page if not authenticated
  } else {
    next();
  }
});


export default router;