// import axios from "axios";
import { BACKEND_URL } from "./config";
import {axiosInstance} from './auth-service';

export async function getHeaderValues() {
  const res = await axiosInstance.get(`${BACKEND_URL}/header-values`);
  return res.data;
}

export async function setHeaderValues(opts) {
  const res = await axiosInstance.post(`${BACKEND_URL}/header-values`, {...opts});
  return res.data;
}

export async function getTotalDuration() {
  const res = await axiosInstance.get(`${BACKEND_URL}/total-duration`);
  return res.data;
}